body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #121b28;
  overflow-x: hidden;
}
.custom-container {
  margin-left: auto;
  margin-right: auto;
}

.service-text {
  font-size: 25px;
  font-weight: 700;
  font-family: "Poppins";
  color: rgba(255, 255, 255, 1);
  margin-bottom: 2rem;
  margin-top: 1.5rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.grid-container-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
}

.mobileAppImg {
  position: relative;
  width: 25rem;
  height: auto;
}

.mobileAppImg img {
  position: relative;
  width: 25rem;
  height: 25rem;
  margin-top: 10%;
  z-index: 2;
}

.shadowBox {
  position: absolute;
  width: 24rem;
  height: 20rem;
  top: 26%;
  background: rgba(42, 55, 63, 0.7);
  border-radius: 0.625rem;
  z-index: 1;
  overflow: visible;
}

.eclipse-image {
  position: absolute;
  width: 40%;
  top: 30rem;
  left: 0;
  z-index: 1;
}

.eclipse-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  left: 0;
}

.text-section {
  width: 38rem;
  height: auto;
  margin-top: 15%;
  margin-left: 4rem;
}

.text-section .heading {
  text-align: left;
  margin-bottom: 2rem;
}
.text-section .title {
  font-family: "Inter";
  font-size: 3rem;
  font-weight: 800;
  line-height: 3.6rem;
  text-align: left;
  color: rgba(31, 201, 255, 1);
}
.text-section p {
  font-size: 1rem;
  line-height: 2rem;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  text-align: justify;
}
.group-image {
  display: flex;
  justify-content: end;
}

.group-image img {
  width: 2.5rem;
  height: 36.5rem;
}
.group2-image {
  display: flex;
  justify-content: flex-start;
  width: 2.8rem;
  height: auto;
}
.group2-image img {
  width: 2.8rem;
  height: 35.5rem;
}
.text-section-2 {
  width: 38rem;
  height: auto;
  margin-top: 10%;
}

.text-section-2 .heading-2 {
  text-align: left;
  margin-bottom: 2.5rem;
}

.text-section-2 .title-2 {
  font-family: "Inter";
  font-size: 3rem;
  font-weight: 800;
  line-height: 3.6rem;
  text-align: left;
  color: rgba(31, 201, 255, 1);
}

.text-section-2 p {
  font-size: 1rem;
  line-height: 2.2rem;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  text-align: justify;
}
.webAppImg {
  position: relative;
  margin-top: 4rem;
  width: 24rem;
  height: auto;
}

.webAppImg img {
  position: relative;
  z-index: 2;
  width: 24rem;
  height: 24rem;
}

.circle-shadow-image {
  position: absolute;
  top: 8rem;
  left: 11rem;
  width: 17.5rem;
  height: 18rem;
  background-color: #232a32;
  border-radius: 75rem;
  z-index: 1;
  overflow: visible;
}
.shadow-box-2 {
  position: absolute;
  width: 25rem;
  height: 23.5rem;
  background-color: #24292f;
  border-radius: 2rem;
  margin-left: -6rem;
  z-index: 1;
}
.eclipse-image-2 {
  position: absolute;
  width: 30%;
  top: 105rem;
  right: 0;
  left: auto;
  z-index: 1;
}

.eclipse-image-2 img {
  width: 100%;
  height: auto;
  object-fit: cover;
  left: 0;
}

.cloudServiceImg {
  position: relative;
  width: 25rem;
  height: auto;
}

.cloudServiceImg img {
  position: absolute;
  width: 20rem;
  height: 22rem;
  top: 5.4rem;
  left: 4.2rem;
  z-index: 2;
}
.text-section-3 {
  width: 38rem;
  height: auto;
  margin-top: 15%;
  margin-left: 4rem;
}
.text-section-3 .heading {
  text-align: left;
  margin-bottom: 1.5rem;
}

.text-section-3 .title-3 {
  font-family: "Inter";
  font-size: 3rem;
  font-weight: 800;
  line-height: 3.6rem;
  text-align: left;
  color: rgba(255, 185, 103, 1);
}
.text-section-3 p {
  font-size: 1rem;
  line-height: 2rem;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  text-align: justify;
}
.group-image-3 {
  display: flex;
  justify-content: end;
}

.group-image-3 img {
  width: 2.6rem;
  height: 35.5rem;
}
.group4-image {
  display: flex;
  justify-content: flex-start;
  width: 2.5rem;
  height: auto;
}

.group4-image img {
  width: 2.5rem;
  height: 35.5rem;
}
.text-section-4 {
  width: 38rem;
  height: auto;
  margin-top: 15%;
}

.text-section-4 .heading-4 {
  text-align: left;
  margin-bottom: 2rem;
}

.text-section-4 .title-4 {
  font-family: "Inter";
  font-size: 3rem;
  font-weight: 800;
  line-height: 3.6rem;
  text-align: left;
  color: rgba(31, 201, 255, 1);
}

.text-section-4 p {
  font-size: 1rem;
  line-height: 2.2rem;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  text-align: justify;
}
.devopsImg {
  position: relative;
  margin-top: 4rem;
  width: 24rem;
  height: auto;
}

.devopsImg img {
  position: relative;
  z-index: 2;
  width: 20rem;
  height: 18rem;
}

.shadow-box-4 {
  position: absolute;
  left: 4rem;
  top: -4rem;
  width: 30rem;
  height: 22rem;
  background-color: #24292f;
  border-radius: 2rem;
  z-index: 1;
  overflow: hidden;
}
.shadow-box-5 {
  position: absolute;
  width: 29rem;
  height: 20rem;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 2rem;
  z-index: 1;
  left: -10rem;
}

.analyticsImg {
  position: relative;
  width: 25rem;
  height: auto;
}

.analyticsImg img {
  width: 18rem;
  height: 18rem;
  z-index: 2;
  transform: translate(3rem, 3rem);
}

.text-section-5 {
  margin-top: 6%;
  width: 38rem;
  height: auto;
  margin-left: 4rem;
}
.text-section-5 .heading-5 {
  text-align: left;
  margin-bottom: 1.9rem;
}

.text-section-5 .title-5 {
  font-family: "Inter";
  font-size: 3rem;
  font-weight: 800;
  line-height: 3.6rem;
  text-align: left;
  color: rgba(208, 132, 255, 1);
}
.text-section-5 p {
  font-size: 1rem;
  line-height: 2rem;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  text-align: justify;
}
.group-image-5 {
  display: flex;
  justify-content: end;
}

.group-image-5 img {
  width: 2.5rem;
  height: 35.5rem;
}
.trust p {
  font-size: 25px;
  font-weight: 700;
  font-family: "Poppins";
  line-height: 37px;
  text-align: center;
  margin: 0;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 2rem;
}
.trusted-by {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.trusted-by a {
  flex: 1;
}
.trusted-by img {
  width: 150px;
  height: auto;
  object-fit: cover;
  position: relative;
  z-index: 2;
}

.purple-image {
  position: absolute;
  width: 40%;
  height: auto;
  top: 200rem;
  left: 0;
  z-index: 1;
}

.purple-image img {
  width: 150%;
  height: auto;
  object-fit: cover;
  left: 0;
}

.mapImage img {
  width: 100%;
  height: auto;
}
.darkGrey-image {
  position: absolute;
  width: 50%;
  top: 239rem;
  right: 0;
  left: auto;
  z-index: 1;
}

.darkGrey-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.footer-image {
  position: absolute;
  width: 15%;
  z-index: 1;
}
.footer-image img {
  width: 150%;
  height: auto;
  object-fit: cover;
}
.footer {
  background-color: #3b3e45;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  text-align: left;
  width: 100%;
  height: auto;
  padding: 1rem;
}
.footer-column {
  margin: 1rem;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.footer-column ul {
  position: relative;
  z-index: 2;
  list-style: none;
  padding: 0;
}
.footer-column a {
  text-decoration: none;
}

.footer-column p {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 1);
}

.footer-column ul li {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: rgba(255, 255, 255, 1);
}

.footer-column .icon {
  width: 0.8rem;
  height: auto;
  margin-right: 10px;
  vertical-align: middle;
}

.copy-rights p {
  font-family: "Prompt";
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin: auto;
  padding: 5px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

@media (max-width: 500px) {
  .service-text {
    font-size: 1.2rem;
  }

  .grid-container-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
  }
  .eclipse-image {
    width: 0;
  }

  .mobileAppImg {
    width: 15rem;
    height: auto;
  }

  .mobileAppImg img {
    width: 15rem;
    height: auto;
    margin: 0;
  }

  .shadowBox {
    width: 100%;
    height: 70%;
    top: 4rem;
  }

  .group-image {
    position: absolute;
    right: 2rem;
    left: auto;
    width: 1.5rem;
    height: auto;
    margin-top: -26rem;
  }
  .group-image img {
    width: 1.5rem;
    height: auto;
  }

  .text-section {
    width: 100%;
    margin: 0;
    padding: 1rem;
    text-align: center;
    order: 3;
  }

  .text-section .title {
    text-align: center;
    font-size: 2rem;
  }

  .text-section p {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
  .grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .webAppImg {
    width: 15rem;
    height: auto;
    margin: 0;
  }
  .webAppImg img {
    width: 15rem;
    height: auto;
  }
  .circle-shadow-image {
    position: absolute;
    right: 0;
    left: 0;
    width: 8rem;
    height: 8rem;
  }
  .group2-image {
    position: absolute;
    right: 2rem;
    left: auto;
    width: 1.5rem;
    height: auto;
    margin-top: -26rem;
  }
  .group2-image img {
    width: 1.5rem;
    height: auto;
  }
  .text-section-2 {
    width: 100%;
    margin: 1rem;
    padding: 1rem;
    text-align: center;
    order: 3;
  }

  .text-section-2 .title-2 {
    text-align: center;
    font-size: 2rem;
  }

  .text-section-2 p {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
  /* 3 */
  .eclipse-image-2 {
    width: 0;
  }

  .cloudServiceImg {
    width: 11rem;
    height: 11rem;
    margin: 0.5rem;
  }
  .shadow-box-2 {
    width: 12rem;
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .cloudServiceImg img {
    width: 12rem;
    height: 12rem;
    left: 0;
    top: 0rem;
  }
  .group-image-3 {
    position: absolute;
    right: 2rem;
    left: auto;
    width: 1.5rem;
    height: auto;
    margin-top: -26rem;
  }
  .group-image-3 img {
    width: 1.5rem;
    height: auto;
  }
  .text-section-3 {
    width: 100%;
    margin: 1rem;
    padding: 1rem;
    text-align: center;
    order: 3;
  }

  .text-section-3 .title-3 {
    font-size: 2rem;
    text-align: center;
  }

  .text-section-3 p {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
  /* 4 */
  .devopsImg {
    width: 12rem;
    height: auto;
  }
  .devopsImg img {
    width: 12rem;
    height: auto;
    left: 0;
  }
  .shadow-box-4 {
    width: 12rem;
    height: 12rem;
    left: 0;
  }
  .group4-image {
    position: absolute;
    right: 2rem;
    left: auto;
    width: 1.5rem;
    height: auto;
    margin-top: -26rem;
  }
  .group4-image img {
    width: 1.5rem;
    height: auto;
  }
  .text-section-4 {
    width: 100%;
    margin: 0;
    padding: 1rem;
    text-align: center;
    order: 3;
  }
  .text-section-4 .title-4 {
    font-size: 2rem;
    text-align: center;
  }

  .text-section-4 p {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
  /* 5 */
  .analyticsImg {
    width: 12rem;
    height: auto;
  }

  .analyticsImg img {
    width: 12rem;
    height: auto;
    margin-left: -6rem;
    margin-top: -3rem;
  }
  .shadow-box-5 {
    width: 12rem;
    height: 12rem;
    left: 0;
  }
  .group-image-5 {
    position: absolute;
    right: 2rem;
    left: auto;
    width: 1.5rem;
    height: auto;
    margin-top: -20rem;
  }
  .group-image-5 img {
    width: 1.5rem;
    height: auto;
  }

  .text-section-5 {
    width: 100%;
    margin: 0;
    padding: 1rem;
    text-align: center;
    margin-top: 6rem;
    order: 3;
  }

  .text-section-5 .title-5 {
    font-size: 2rem;
    text-align: center;
  }

  .text-section-5 p {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }

  .trusted-by img {
    width: 100%;
    height: auto;
  }
  .mapImage img {
    width: 100%;
    height: auto;
  }
  .purple-image {
    width: 0;
  }
  .darkGrey-image {
    width: 0;
  }
  .footer-image {
    width: 0;
  }

  .footer {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  .footer-column ul li {
    line-height: 1.5rem;
  }

  .footer-column {
    width: 100%;
    margin: 0;
  }
  .social-shadow-box {
    width: 0;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .grid-container {
    width: 100%;
    margin-bottom: 2rem;
  }

  .grid-container-1 {
    width: 100%;
    margin-bottom: 2rem;
    overflow: hidden;
  }
  .eclipse-image {
    margin-top: 24rem;
    width: 35rem;
  }
  .mobileAppImg {
    width: 15rem;
    height: auto;
  }
  .mobileAppImg img {
    width: 15rem;
    height: 15rem;
    margin-top: 50%;
  }
  .shadowBox {
    width: 15rem;
    height: 15rem;
  }

  .text-section .heading {
    margin-top: 2rem;
    width: 11rem;
  }

  .text-section {
    margin-left: 2rem;
    width: 22rem;
    height: auto;
  }

  .text-section .title {
    font-size: 2rem;
    width: 16rem;
  }

  .text-section p {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .group-image {
    display: flex;
    justify-content: flex-end;
  }

  .text-section-2 {
    margin-top: 5rem;
    width: 22rem;
    height: auto;
  }

  .text-section-2 .title-2 {
    font-size: 2rem;
    width: 13rem;
  }
  .text-section-2 p {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  .webAppImg {
    width: 15rem;
    height: auto;
  }

  .webAppImg img {
    width: 15rem;
    height: 15rem;
    margin-top: 30%;
    margin-left: 0;
  }
  .circle-shadow-image {
    left: 45%;
    width: 12rem;
    height: 12rem;
  }
  .eclipse-image-2 {
    width: 35rem;
    margin-top: 20rem;
  }

  .cloudServiceImg {
    width: 15rem;
    height: auto;
  }
  .cloudServiceImg img {
    width: 12rem;
    height: 12rem;
    margin-top: 2rem;
  }
  .shadow-box-2 {
    height: 17rem;
    width: 20rem;
  }

  .text-section-3 .heading-3 {
    margin-top: 10%;
    width: 11rem;
  }

  .text-section-3 {
    margin-left: 2rem;
    width: 22rem;
    height: auto;
  }

  .text-section-3 .title-3 {
    font-size: 2rem;
  }

  .text-section-3 p {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .group4-image {
    display: flex;
    justify-content: flex-start;
  }
  .text-section-4 {
    width: 22rem;
    height: auto;
  }

  .text-section-4 .title-4 {
    font-size: 2rem;
  }
  .text-section-4 p {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  .devopsImg {
    width: 15rem;
    height: auto;
  }

  .devopsImg img {
    width: 15rem;
    height: 12rem;
    margin-top: 35%;
  }
  .shadow-box-4 {
    width: 15rem;
    height: 18rem;
    left: 35%;
  }
  .shadow-box-5 {
    width: 25rem;
    height: 15rem;
  }
  .analyticsImg {
    width: 15rem;
    height: auto;
  }
  .analyticsImg img {
    width: 12rem;
    height: 12rem;
    margin-top: 1rem;
    margin-left: -2rem;
  }

  .text-section-5 .heading-5 {
    margin-top: 10%;
    width: 11rem;
  }
  .text-section-5 {
    margin-left: 2rem;
    width: 22rem;
    height: auto;
  }
  .text-section-5 .title-5 {
    font-size: 2rem;
    width: 16rem;
  }

  .text-section-5 p {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  .group-image-5 {
    display: flex;
    justify-content: flex-end;
  }

  .purple-image {
    width: 25rem;
    margin-top: 50rem;
  }
  .darkGrey-image {
    width: 35rem;

    margin-top: 35rem;
  }

  .footer {
    flex-wrap: wrap;
    padding: 2rem;
  }
  .footer-column ul li {
    line-height: 2rem;
  }
}
@media (min-width: 1440px) {
  .text-section {
    margin-top: 15%;
    margin-left: 6rem;
    width: 36rem;
  }
  .text-section-2 {
    margin-left: -15rem;
    width: 36rem;
  }

  .text-section-3 {
    margin-top: 15%;
    margin-left: 6rem;

    width: 36rem;
  }
  .text-section-4 {
    margin-left: -15rem;
    width: 36rem;
  }

  .text-section-5 {
    margin-top: 6%;
    margin-left: 6rem;
    width: 36rem;
  }
  .eclipse-image {
    margin-top: 12rem;
  }
  .eclipse-image-2 {
    margin-top: 15rem;
  }
  .purple-image {
    margin-top: 12rem;
  }
  .darkGrey-image {
    margin-top: 10rem;
  }
}

@media screen and (min-width: 2560px) {
  .grid-container {
    width: 100%;
    gap: 2.5rem;
    margin-bottom: 2rem;
  }
  .grid-container-1 {
    width: 100%;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .service-text {
    font-size: 2rem;
    margin-top: 2.5rem;
  }
  .eclipse-image {
    margin-top: 28rem;
  }

  .eclipse-image-2 {
    margin-top: 35rem;
  }
  .purple-image {
    width: 60rem;
  }
  .darkGrey-image {
    margin-top: 32rem;
  }

  .text-section {
    margin-left: 4rem;
  }

  .text-section-3 {
    margin-left: 3rem;
  }
  .text-section-5 {
    margin-left: 4rem;
  }

  .mapImage img {
    width: 100%;
    height: auto;
  }
  .footer-pink {
    margin-top: -8rem;
  }

  .copy-rights p {
    font-size: 15px;
  }
}
