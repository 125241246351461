.large_circle,
.small_circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  position: fixed; 
  pointer-events: none;
  transition: transform 0.2s;
  z-index: 1001;
}
.small_circle{
    width: 12px;
    height: 12px;
    background-color: rgb(42, 40, 40);
}
