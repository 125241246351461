body html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

header {
  width: 100vw;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.back img {
  width: auto;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.header-content {
  margin-bottom: 150px;
  color: rgba(255, 255, 255, 1);
}
.cube-image {
  position: relative;
  width: 89.02px;
  height: 87.67px;
  left: -19.5rem;
  top: 9rem;
}

.cone-image {
  position: relative;
  width: 50.02px;
  height: 50.02px;
  top: 30%;
  left: 0%;
  transform: translate(10px, 90px);
}

.sphere-image {
  position: absolute;
  width: 33px;
  height: 33px;
  top: 124.56px;
  right: 26%;
}

.header-content h2 {
  width: 654px;
  height: 98px;
  font-size: 60.41px;
  font-weight: 600;
  line-height: 98.12px;
}

.buttons {
  background-color: #53565b;
  border-radius: 11.46px;
  color: white;
  font-size: 30px;
  font-weight: 600;
  width: 228px;
  height: 56px;
  align-items: center;
  justify-content: center;
  line-height: 45px;
  border: none;
  overflow: hidden;
  position: relative;
}
.blue-box {
  position: absolute;
  left: 5px;
  top: 30%;
  bottom: 20%;
  transform: translateY(-50%) rotate(320deg);
  width: 35px;
  height: 55px;
  background-color: #4a77ba;
  border-radius: 50%;
  filter: blur(10px);
}

@media (max-width: 500px) {
  .sphere-image {
    left: 10rem;
    top: 18rem;
    width: 2rem;
    height: auto;
  }
  .header-content h2 {
    position: absolute;
    width: 120px;
    height: auto;
    left: 2rem;
    top: 15rem;
    font-size: 35px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;
  }
  .btn-container button {
    position: absolute;
    left: 2rem;
  }

  .buttons {
    font-size: 18px;
    font-weight: 600;
    width: 150px;
    height: 45px;
    display: flex;
    text-align: center;
    justify-content: center;
    overflow: visible;
    z-index: 1;
    top: 26rem;
  }
  .cone-image {
    position: absolute;
    width: 4rem;
    height: auto;
    left: 5rem;
    top: 22rem;
  }
  .blue-box {
    top: -8rem;
    width: 4rem;
    height: 4rem;
    z-index: 0;
    filter: blur(40px);
  }
  .cube-container .cube-image {
    position: absolute;
    width: 2rem;
    height: auto;
    margin-top: 25rem;
    left: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .back img {
    width: 45vh;
    height: 100vh;
    object-fit: cover;
  }
  .sphere-image {
    top: 15rem;
    right: 5rem;
  }
}
@media (min-width: 1440px) {
  .back img {
    width: 98vh;
    height: 100vh;
    object-fit: cover;
  }
  .sphere-image{
    margin-top: 6%;
  }
}
@media (min-width: 2560px) {
  /* .back img {
    width: 90vh;
    height: 100vh;
    object-fit: cover;
  } */
  .sphere-image {
    top: 15rem;
  }
}
