.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background: linear-gradient(to right, #3c4161, #34373e, #353d3f);
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

.nav-links.inActive {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-right: 40px;
}

.nav-links a {
  font-weight: 600;
  font-size: 14px;
  margin-left: 20px;
  margin-right: 20px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0.5px;
  cursor: pointer;
  text-decoration: none;
}
.menu-bar {
  display: none;
}

@media (max-width: 500px) {
  a {
    text-decoration: none;
    color: #565353;
    cursor: pointer;
  }
  .menu-bar {
    display: block;
  }
  .HamburgerMenu {
    cursor: pointer;
  }
}
