body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #121b28;
}
body::-webkit-scrollbar {
  display: none;
}

.front-section {
  position: relative;
  background-image: url("./images/Rectangle2.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 85vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.green-glosy-image {
  position: absolute;
  width: 56px;
  height: 64px;
  top: 100px;
  left: 50px;
}

.pink-image {
  position: absolute;
  width: 130px;
  height: 310px;
  bottom: 3rem;
  background-image: url(../components/images/RoundCubePurpleGlossy.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.ring-box {
  position: absolute;
  width: 255px;
  height: 226px;
  bottom: 1rem;
  right: 0;
  left: auto;
}

/* Responsive Styles */

@media (max-width: 500px) {
  body {
    overflow-x: hidden;
  }
  .front-section {
    height: 75vh;
  }

  .pink-image {
    position: absolute;
    top: 5rem;
    right: 0;
    left: auto;
    width: 80px;
    height: 10rem;
    z-index: 999;
    background-image: url(../components/images/Mobile-Purple-Glossy.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .green-glosy-image {
    width: 3rem;
    filter: blur(8px);
    height: auto;
    top: 23rem;
    position: absolute;
    right: 0;
    left: auto;
  }

  .ring-box {
    position: absolute;
    width: 6rem;
    height: auto;
    align-items: end;
    bottom: 0;
    right: 0;
  }
}

@media (min-width: 501px) and (max-width: 800px) {
  .cube-image {
    top: 40%;
    left: 9%;
  }

  .cone-image {
    top: 43%;
    left: 45%;
  }

  .pink-image {
    top: 40%;
    left: 0%;
  }

  .ring-box {
    top: 58%;
    left: 67%;
  }
}

@media (min-width: 801px) and (max-width: 1200px) {
  .cube-image {
    top: 40%;
    left: 6%;
  }

  .cone-image {
    top: 43%;
    left: 45%;
  }

  .pink-image {
    top: 40%;
    left: 0%;
  }

  .ring-box {
    top: 58%;
    left: 69%;
  }
}
@media (min-width: 1440px) {
  .pink-image {
    bottom: 8rem;
  }
  .ring-box {
    bottom: 2rem;
  }
}

@media (min-width: 2560px) and (max-width: 3000px) {
  .cube-image {
    top: 40%;
    left: 34.7%;
  }

  .cone-image {
    top: 42%;
    left: 47%;
  }

  .pink-image {
    top: 40%;
    left: 0%;
  }

  .ring-box {
    top: 54%;
    left: 90%;
  }
}
